<template>
  <tv-dashboard-tabs></tv-dashboard-tabs>
</template>

<script>
import TvDashboardTabs from "../Tabs/TvDashboardTabs/TvDashboardTabs";

export default {
  components: { TvDashboardTabs },
  data: () => ({}),
  created() {
    let timeout;

    // Add event to hide cursor if inactive
    window.addEventListener("mousemove", () => {
      // Stop timer if moving
      clearTimeout(timeout);

      // Show cursor
      document.documentElement.style.cssText = "";

      // Start timer to hide cursor
      timeout = setTimeout(() => {
        document.documentElement.style.cssText = "cursor:none !important;";
      }, 500);
    });

    let refreshTimeout;

    window.addEventListener("mousedown", () => {
      refreshTimeout = setTimeout(() => {
        location.reload();
      }, 2000);
    });

    window.addEventListener("mouseup", () => {
      clearTimeout(refreshTimeout);
    });
  }
};
</script>
