<template>
  <v-container fluid grid-list-xl class="p-0">
    <v-progress-linear
      class="mt-1"
      indeterminate
      color="primary"
      :style="loadingStats < 5 ? '' : 'visibility:hidden;'"
    ></v-progress-linear>

    <v-layout wrap>
      <v-flex xs12 sm6 md4 lg2>
        <stat-widget
          icon="fal fa-hand-point-up"
          title="Klik"
          :total="total.clicks"
          :predicted="predicted.clicks"
          :current="formattedHourlyData.clicks"
          :previous="formattedPreviousHourlyData.clicks"
        ></stat-widget>
      </v-flex>
      <v-flex xs12 sm6 md4 lg2>
        <stat-widget
          icon="fal fa-fingerprint"
          title="Unikke klik"
          :total="total.unique_clicks"
          :predicted="predicted.unique_clicks"
          :current="formattedHourlyData.unique_clicks"
          :previous="formattedPreviousHourlyData.unique_clicks"
        ></stat-widget>
      </v-flex>
      <v-flex xs12 sm6 md4 lg2>
        <stat-widget
          icon="fal fa-shopping-basket"
          title="Salg"
          :total="total.sales"
          :predicted="predicted.sales"
          :conversion="total.conversion + '%'"
          :current="formattedHourlyData.sales"
          :previous="formattedPreviousHourlyData.sales"
        ></stat-widget>
      </v-flex>
      <v-flex xs12 sm6 md4 lg2>
        <stat-widget
          icon="fal fa-money-bill-wave"
          title="Kommission"
          :total="total.commission | local_numbers"
          :predicted="predicted.commission | local_numbers"
          :current="formattedHourlyData.commission"
          :previous="formattedPreviousHourlyData.commission"
        ></stat-widget>
      </v-flex>
      <v-flex xs12 sm6 md4 lg2>
        <stat-widget
          icon="fal fa-coins"
          title="Omsætning"
          :total="total.revenue | local_numbers"
          :predicted="predicted.revenue | local_numbers"
          :current="formattedHourlyData.revenue"
          :previous="formattedPreviousHourlyData.revenue"
        ></stat-widget>
      </v-flex>
      <v-flex xs12 sm6 md4 lg2>
        <time-weather-widget></time-weather-widget>
      </v-flex>

      <v-flex xs12 lg4>
        <v-card>
          <v-card-title>
            <div class="headline">Top influencere</div>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="[
                { text: 'Influencer', value: 'influencer.user.first_name' },
                { text: 'Klik', value: 'clicks' },
                { text: 'Salg', value: 'sales' }
              ]"
              :items="topInfluencers"
              hide-default-footer
            >
              <template slot="items" slot-scope="props">
                <td>
                  {{ props.item.influencer.user.first_name }}
                  {{ props.item.influencer.user.last_name }}
                </td>
                <td>{{ props.item.clicks }}</td>
                <td>{{ props.item.sales }}</td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 lg4>
        <v-card>
          <v-card-title>
            <div class="headline">Top virksomheder</div>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="[
                { text: 'Virksomhed', value: 'business.display_name' },
                { text: 'Klik', value: 'clicks' },
                { text: 'Salg', value: 'sales' }
              ]"
              :items="topBusinesses"
              hide-default-footer
            >
              <template slot="items" slot-scope="props">
                <td>
                  {{ props.item.business.display_name }}
                </td>
                <td>{{ props.item.clicks }}</td>
                <td>{{ props.item.sales }}</td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 lg4>
        <v-card>
          <v-card-title>
            <div class="headline">Trafik seneste 5min</div>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="[
                { text: 'Influencer', value: 'influencer.user.first_name' },
                { text: 'Klik', value: 'clicks' },
                { text: 'Salg', value: 'sales' }
              ]"
              :items="currentTraffic"
              hide-default-footer
            >
              <template slot="items" slot-scope="props">
                <td>
                  {{ props.item.influencer.user.first_name }}
                  {{ props.item.influencer.user.last_name }}
                </td>
                <td>{{ props.item.clicks }}</td>
                <td>{{ props.item.sales }}</td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import local_numbers from "@/helpers/filters/local_numbers";
import StatWidget from "../../../cards/widgets/StatWidget";
import TimeWeatherWidget from "../../../cards/widgets/TimeWeatherWidget";
import { mapActions } from "vuex";

export default {
  components: { TimeWeatherWidget, StatWidget },
  filters: { local_numbers },
  data: () => ({
    loadingStats: 0,
    interval: null,
    hourlyStats: [],
    previousHourlyStats: [],
    weather: null,
    topBusinesses: [],
    topInfluencers: [],
    currentTraffic: []
  }),
  computed: {
    ...mapState("settings", ["settings"]),
    formattedHourlyData() {
      let final = {
        clicks: [],
        unique_clicks: [],
        sales: [],
        revenue: [],
        commission: []
      };

      let now = this.$moment();

      // Loop for 24 hours and check if any data
      for (let i = 0; i < 24; i++) {
        let dateTime = this.$moment
          .utc()
          .set({ hour: i, minute: 0, second: 0, millisecond: 0 })
          .local();

        // If hour hasn't passed skip.
        if (i > parseInt(now.format("HH"))) {
          continue;
        }

        let hasHour = this.hourlyStats.filter(
          item => parseInt(item.hour) === i
        );

        // No data for this hour
        if (hasHour.length === 0) {
          final.clicks.push([dateTime.format(), 0]);
          final.unique_clicks.push([dateTime.format(), 0]);
          final.sales.push([dateTime.format(), 0]);
          final.revenue.push([dateTime.format(), 0]);
          final.commission.push([dateTime.format(), 0]);
        } else {
          final.clicks.push([dateTime.format(), hasHour[0].clicks]);
          final.unique_clicks.push([
            dateTime.format(),
            hasHour[0].unique_clicks
          ]);
          final.sales.push([dateTime.format(), hasHour[0].sales]);
          final.revenue.push([dateTime.format(), hasHour[0].value]);
          final.commission.push([dateTime.format(), hasHour[0].commission]);
        }
      }

      return final;
    },
    formattedPreviousHourlyData() {
      let final = {
        clicks: [],
        unique_clicks: [],
        sales: [],
        revenue: [],
        commission: []
      };

      let now = this.$moment();

      // Loop for 24 hours and check if any data
      for (let i = 0; i < 24; i++) {
        let dateTime = this.$moment()
          .utc()
          .subtract(1, "day")
          .set({ hour: i, minute: 0, second: 0, millisecond: 0 })
          .local();

        // If hour hasn't passed skip.
        if (i > parseInt(now.format("HH"))) {
          continue;
        }

        let hasHour = this.previousHourlyStats.filter(
          item => parseInt(item.hour) === i
        );

        // No data for this hour
        if (hasHour.length === 0) {
          final.clicks.push([dateTime.format(), 0]);
          final.unique_clicks.push([dateTime.format(), 0]);
          final.sales.push([dateTime.format(), 0]);
          final.revenue.push([dateTime.format(), 0]);
          final.commission.push([dateTime.format(), 0]);
        } else {
          final.clicks.push([dateTime.format(), hasHour[0].clicks]);
          final.unique_clicks.push([
            dateTime.format(),
            hasHour[0].unique_clicks
          ]);
          final.sales.push([dateTime.format(), hasHour[0].sales]);
          final.revenue.push([dateTime.format(), hasHour[0].value]);
          final.commission.push([dateTime.format(), hasHour[0].commission]);
        }
      }

      return final;
    },
    total() {
      let final = {
        clicks: 0,
        unique_clicks: 0,
        sales: 0,
        revenue: 0,
        commission: 0,
        conversion: 0
      };

      this.hourlyStats.forEach(function(item) {
        final.clicks += parseInt(item.clicks);
        final.unique_clicks += parseInt(item.unique_clicks);
        final.sales += parseInt(item.sales);
        final.revenue += parseFloat(item.value);
        final.commission += parseFloat(item.commission);
      });

      final.revenue = Math.round(final.revenue * 100) / 100;
      final.commission = Math.round(final.commission * 100) / 100;

      if (final.sales && final.clicks) {
        final.conversion =
          Math.round((final.sales / final.unique_clicks) * 100 * 100) / 100;
      }

      return final;
    },
    predicted() {
      let hour = this.$moment()
        .utc()
        .format("HH");
      hour = parseInt(hour); // Convert to int so we can calculate

      let avgClickPerHour = this.total.clicks / hour;
      let avgUniqueClicksPerHour = this.total.unique_clicks / hour;
      let avgSalesPerHour = this.total.sales / hour;
      let avgRevenuePerHour = this.total.revenue / hour;
      let avgCommissionPerHour = this.total.commission / hour;

      return {
        clicks: Math.round(24 * avgClickPerHour),
        unique_clicks: Math.round(24 * avgUniqueClicksPerHour),
        sales: Math.round(24 * avgSalesPerHour),
        revenue: Math.round(24 * avgRevenuePerHour),
        commission: Math.round(24 * avgCommissionPerHour)
      };
    }
  },
  methods: {
    ...mapActions("core", ["getStats"]),

    getHourlyStats() {
      const startingAt = this.$moment().startOf("day");
      const endingAt = this.$moment().endOf("day");

      const todayParams = {
        start_date: startingAt.utc().format("YYYY-MM-DD"),
        end_date: endingAt.utc().format("YYYY-MM-DD"),
        starting_at: startingAt.utc().format("YYYY-MM-DD HH:mm:ss"),
        ending_at: endingAt.utc().format("YYYY-MM-DD HH:mm:ss"),
        group: ["hour"]
      };
      this.getStats(todayParams).then(response => {
        this.hourlyStats = response;
        this.loadingStats++;
      });
      const yesterdayParams = {
        start_date: startingAt.subtract(1, "day").format("YYYY-MM-DD"),
        end_date: endingAt.subtract(1, "day").format("YYYY-MM-DD"),
        starting_at: startingAt
          .subtract(1, "day")
          .format("YYYY-MM-DD HH:mm:ss"),
        ending_at: endingAt.subtract(1, "day").format("YYYY-MM-DD HH:mm:ss"),
        group: ["hour"]
      };

      this.getStats(yesterdayParams).then(response => {
        this.previousHourlyStats = response;
        this.loadingStats++;
      });
    },
    getTopBusinesses() {
      const params = {
        start_date: this.$moment()
          .startOf("day")
          .utc()
          .format("YYYY-MM-DD"),
        end_date: this.$moment()
          .endOf("day")
          .utc()
          .format("YYYY-MM-DD"),
        starting_at: this.$moment()
          .startOf("day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        ending_at: this.$moment()
          .endOf("day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        group: ["business"],
        limit: 9
      };
      this.getStats(params).then(response => {
        this.topBusinesses = response;
        this.loadingStats++;
      });
    },
    getTopInfluencers() {
      const params = {
        start_date: this.$moment()
          .startOf("day")
          .utc()
          .format("YYYY-MM-DD"),
        end_date: this.$moment()
          .endOf("day")
          .utc()
          .format("YYYY-MM-DD"),
        starting_at: this.$moment()
          .startOf("day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        ending_at: this.$moment()
          .endOf("day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        group: ["influencer"],
        limit: 9
      };
      this.getStats(params).then(response => {
        this.topInfluencers = response;
        this.loadingStats++;
      });
    },
    getCurrentTraffic() {
      const params = {
        start_date: this.$moment()
          .subtract(5, "minutes")
          .utc()
          .format("YYYY-MM-DD"),
        end_date: this.$moment()
          .utc()
          .format("YYYY-MM-DD"),
        starting_at: this.$moment()
          .subtract(5, "minutes")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        ending_at: this.$moment()
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        group: ["influencer"]
      };
      this.getStats(params).then(response => {
        this.currentTraffic = response;
        this.loadingStats++;
      });
    },
    getAllStats() {
      this.loadingStats = 0;

      this.getHourlyStats();
      this.getTopBusinesses();
      this.getTopInfluencers();
      this.getCurrentTraffic();
    }
  },
  created() {
    // Init stats first load
    this.getAllStats();

    let seconds = 10;

    // If app is local(Dev)
    if (this.settings.app_env === "local") {
      // Every 60min
      seconds = 60 * 60;
    }

    this.interval = setInterval(() => {
      this.getAllStats();
    }, seconds * 1000);
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>
