<template>
  <v-container fluid grid-list-xl class="p-0">
    <v-progress-linear
      class="mt-1"
      indeterminate
      color="primary"
      :style="loadingStats < 4 ? '' : 'visibility:hidden;'"
    ></v-progress-linear>

    <v-layout wrap>
      <v-flex xs12 sm6 md4>
        <v-card class="flex-card" style="height: 100%">
          <v-card-title>
            <div class="headline">Aktivitet seneste 30 dage</div>
          </v-card-title>
          <v-card-text class="d-flex align-center">
            <business-activity-chart v-model="activityStats" />
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 md4>
        <v-card class="flex-card" style="height: 100%">
          <v-card-title>
            <div class="headline">Aktivitet forrige 30 dage</div>
          </v-card-title>
          <v-card-text class="d-flex align-center">
            <business-activity-chart v-model="activityStatsLastPeriod" />
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 md4>
        <v-card class="flex-card" style="height: 100%">
          <v-card-title>
            <div class="headline">Abonnementer</div>
          </v-card-title>
          <v-card-text class="d-flex align-center">
            <subscriptions-chart v-model="subscriptionChartData" />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import BusinessActivityChart from "../../charts/BusinessActivityChart";
import SubscriptionsChart from "../../charts/SubscriptionsChart";
import { mapActions } from "vuex";

export default {
  components: { SubscriptionsChart, BusinessActivityChart },
  filters: { local_numbers },
  data: () => ({
    interval: null,
    loadingStats: 0,
    activityStats: {},
    activityStatsLastPeriod: {},
    totalSubscriptions: 0,
    totalCanceledSubscriptions: 0
  }),
  computed: {
    subscriptionChartData() {
      return {
        active: this.totalSubscriptions - this.totalCanceledSubscriptions,
        canceled: this.totalCanceledSubscriptions
      };
    }
  },
  methods: {
    ...mapActions("moderator", ["getBusinessActivityStats"]),
    ...mapActions("core", ["getBusinesses"]),

    getActivityStats() {
      const params = {
        starting_at: this.$moment()
          .startOf("day")
          .subtract(30, "day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        ending_at: this.$moment()
          .endOf("day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")
      };

      this.getBusinessActivityStats(params).then(response => {
        this.activityStats = response;
        this.loadingStats++;
      });
    },
    getActivityStatsLastPeriod() {
      const params = {
        starting_at: this.$moment()
          .startOf("day")
          .subtract(60, "day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        ending_at: this.$moment()
          .endOf("day")
          .subtract(30, "day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")
      };

      this.getBusinessActivityStats(params).then(response => {
        this.activityStatsLastPeriod = response;
        this.loadingStats++;
      });
    },
    getTotalSubscriptions() {
      const params = {
        has_subscription: true,
        page: 1,
        limit: 100
      };
      this.getBusinesses(params).then(response => {
        this.totalSubscriptions = response.total;
        this.loadingStats++;
      });
    },
    getTotalCanceledSubscriptions() {
      const params = {
        has_subscription: true,
        has_subscription_canceled: true,
        page: 1,
        limit: 100
      };
      this.getBusinesses(params).then(response => {
        this.totalCanceledSubscriptions = response.total;
        this.loadingStats++;
      });
    },
    getStats() {
      this.loadingStats = 0;

      this.getActivityStats();
      this.getActivityStatsLastPeriod();
      this.getTotalSubscriptions();
      this.getTotalCanceledSubscriptions();
    }
  },
  created() {
    this.getStats();

    this.interval = setInterval(() => {
      this.getStats();
    }, 60 * 1000);
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>
