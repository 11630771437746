<template>
  <v-chart :options="options" :auto-resize="true"></v-chart>
</template>

<script>
export default {
  props: {
    value: {
      default: () => {}
    }
  },
  computed: {
    options() {
      return {
        series: [
          {
            type: "pie",
            radius: "55%",
            data: this.chartData,
            label: {
              formatter: "{b} \n {c} \n {d}%",
              fontSize: 20
              // position: 'outside',
              // verticalAlign: 'bottom',
              // alignTo: 'edge',
              // margin: '-5%'
            },
            animationEasing: "elasticOut",
            animationDelay: () => {
              return Math.random() * 200;
            }
          }
        ]
      };
    },
    chartData() {
      return [
        // {
        //     name: 'Ikke aktive',
        //     value: this.value.total,
        //     itemStyle: {
        //         color: '#FF1744'
        //     }
        // },
        {
          name: "Cancelled",
          value: this.value.canceled,
          itemStyle: {
            color: "#FF6F00"
          }
        },
        {
          name: "Active",
          value: this.value.active,
          itemStyle: {
            color: "#388E3C"
          }
        }
      ];
    }
  }
};
</script>
