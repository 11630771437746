<template>
  <v-tabs v-model="selectedTab" background-color="transparent">
    <v-tab v-for="tab in tabs" :key="tab.name" class="accent--text">
      {{ tab.text }}
    </v-tab>
    <v-tabs-slider color="accent"></v-tabs-slider>

    <v-tabs-items
      v-model="selectedTab"
      touchless
      class="elevation-0 transparent"
    >
      <v-tab-item v-for="tab in tabs" :key="tab.name">
        <current-day-tab v-if="tab.name === 'current_day'"></current-day-tab>
        <business-stats-tab
          v-else-if="tab.name === 'business'"
        ></business-stats-tab>
        <influencer-stats-tab
          v-else-if="tab.name === 'influencer'"
        ></influencer-stats-tab>
        <v-alert v-else :value="true" type="warning"
          >Under construction</v-alert
        >
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import { mapState } from "vuex";

import CurrentDayTab from "./CurrentDayTab";
import InfluencerStatsTab from "./InfluencerStatsTab";
import BusinessStatsTab from "./BusinessStatsTab";

export default {
  components: { BusinessStatsTab, CurrentDayTab, InfluencerStatsTab },
  data: () => ({
    selectedTab: null,
    interval: null,
    timerVal: 45000,
    tabs: [
      { name: "current_day", text: "I dag" },
      { name: "business", text: "Virksomheder" },
      { name: "influencer", text: "Influencer" }
    ]
  }),
  computed: {
    ...mapState("settings", ["settings"])
  },
  methods: {
    setTabChanger() {
      clearInterval(this.interval);
      if (this.selectedTab !== 1 && this.selectedTab !== 2) {
        this.timerVal = 45000;
      } else {
        this.timerVal = 20000;
      }
      this.interval = setInterval(() => {
        this.selectedTab++;
        console.log("this.selectedTab", this.selectedTab);
      }, this.timerVal);
    }
  },
  watch: {
    selectedTab() {
      this.setTabChanger();
    }
  },
  created() {
    if (this.settings.app_env !== "local") {
      this.interval = setInterval(() => {
        this.selectedTab++;
      }, this.timerVal);
    }
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>
