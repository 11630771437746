<template>
  <v-card class="h-100">
    <v-card-text class="text-center h-100">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <div>
            <div class="mb-5">
              <div class="headline" v-text="date"></div>
              <div class="display-3" v-text="time"></div>
            </div>

            <div class="mb-4">
              <div class="headline">{{ temperature }}° Out</div>
              <div class="display-3">
                <span
                  v-for="(icon, k) in weatherIcons"
                  v-html="icon"
                  :key="k"
                ></span>
              </div>
            </div>
          </div>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    dateFormat: {
      type: String,
      default: "ddd DD/MM"
    },
    timeFormat: {
      type: String,
      default: "HH:mm"
    }
  },
  data: () => ({
    dateTimeInterval: null,
    weatherInterval: null,
    weatherCondition: null,
    date: "",
    time: "",
    rainForecasts: []
  }),
  computed: {
    weatherIcons() {
      if (!this.weatherCondition) {
        return [];
      }

      let icons = [];

      this.weatherCondition.weather.forEach(weatherCondition => {
        icons.push(this.getWeatherIcon(weatherCondition));
      });

      return icons;
    },
    temperature() {
      if (!this.weatherCondition) {
        return;
      }

      return this.weatherCondition.main.temp.toFixed(1);
    }
  },
  methods: {
    refreshTime() {
      this.date = this.$moment().format(this.dateFormat);
      this.time = this.$moment().format(this.timeFormat);
    },
    getWeatherIcon(condition) {
      let isNight = condition.icon.includes("n");

      const group = parseInt(condition.id.toString().charAt(0));

      if (group === 2) {
        return "⛈";
      }

      if (group === 3) {
        return "☔";
      }

      if (group === 5) {
        return "☔";
      }

      if (group === 6) {
        return "☃";
      }

      if (condition.id >= 700 && condition.id <= 762) {
        return "🌫";
      }

      if (condition.id === 781) {
        return "🌪";
      }

      if (condition.id === 771) {
        return "💨";
      }

      if (condition.id === 800) {
        return isNight ? "🌌" : "☀";
      }

      if (condition.id === 801) {
        return "⛅";
      }

      if (group === 8) {
        return "☁";
      }

      return "🧐";
    },
    getCurrentWeatherReport() {
      let url = `https://api.openweathermap.org/data/2.5/weather?zip=1760,dk&appid=fd1f3fce2c3d3e8c5aade76def6b02dd&units=metric`;

      fetch(url).then(response => {
        if (response.ok) {
          response.json().then(data => {
            this.weatherCondition = data;
          });
        }
      });
    }
  },
  created() {
    this.refreshTime();
    this.dateTimeInterval = setInterval(this.refreshTime, 1000);

    this.getCurrentWeatherReport();
    this.weatherInterval = setInterval(
      this.getCurrentWeatherReport,
      15 * 60 * 1000
    );
  },
  destroyed() {
    clearInterval(this.dateTimeInterval);
    clearInterval(this.weatherInterval);
  }
};
</script>
